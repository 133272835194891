@import url("https://fonts.googleapis.com/css2?family=Slackey&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");
@font-face {
  font-family: 'herkules';
  src: url("/fonts/herkules-webfont.woff2") format("woff2"), url("/fonts/herkules-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'recoleta';
  src: url("/fonts/recoleta-regular.woff2") format("woff2"), url("/fonts/recoleta-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'futurabook';
  src: url("/fonts/futura_book_regular-webfont.woff2") format("woff2"), url("/fonts/futura_book_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'aki_linesregular';
  src: url("/fonts/aki_lines_regular-webfont.woff2") format("woff2"), url("/fonts/aki_lines_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'made_dillanregular';
  src: url("/fonts/made_dillan-webfont.woff2") format("woff2"), url("/fonts/made_dillan-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'retrochipsregular';
  src: url("/fonts/retrochips-1gnre-webfont.woff2") format("woff2"), url("/fonts/retrochips-1gnre-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

/* ----------------------------------------------------------- *
 |
 |  Mixins
 |
 * ----------------------------------------------------------- *
 |
 | utilities
 |
 * ----------------------------------------------------------- */
/* ----------------------------------------------------------- *
 |
 |  Mixins
 |
 * ----------------------------------------------------------- *
 |
 | utilities
 |
 * ----------------------------------------------------------- */
body, html {
  height: 100%; }

body {
  background: #EBEBE9;
  font-family: 'futurabook', sans-serif;
  overflow-x: hidden;
  margin: 0; }
  body.modal-open {
    overflow: hidden; }

.font-futura {
  font-family: 'futurabook', sans-serif; }

.font-herklues {
  font-family: 'herkules', serif; }

.font-recoleta {
  font-family: 'recoleta', serif; }

.content {
  white-space: pre-wrap; }

h1 {
  font-family: 'herkules', serif;
  font-size: 3rem;
  margin: 0.2em 0; }
  @media (min-width: 768px) {
    h1 {
      font-size: 3.5rem;
      margin: 0.67em 0; } }

h2 {
  font-family: 'herkules', serif;
  font-size: 1.8rem;
  margin-top: 2.4rem; }
  @media (min-width: 768px) {
    h2 {
      font-size: 2.2rem;
      margin-top: 3.5rem; } }

h3 {
  font-size: 1.2rem;
  margin: 1.6rem 0 0.75rem 0; }

.back-button {
  position: fixed;
  top: 1rem;
  left: 1rem;
  border: 2px solid white;
  border-radius: 0.7rem;
  color: white;
  display: inline-block;
  font-size: 2rem;
  padding: 0 0.3rem 0.3rem 0.3rem;
  text-decoration: none;
  transition: background 0.3s ease, color 0.3s ease; }
  @media (min-width: 768px) {
    .back-button {
      position: fixed;
      top: 2rem;
      left: 2rem;
      padding: 0.2rem 0.5rem 0.5rem 0.5rem;
      font-size: 2.5rem; } }
  .back-button:hover {
    background: white;
    color: black; }

.theme-light .text-th-main {
  color: #D86B47; }

.theme-light .hover\:text-th-main:hover:not(:disabled) {
  color: #D86B47; }

.theme-light .activated\:text-th-main.active {
  color: #D86B47; }

.theme-light .bg-th-main {
  background: #D86B47; }

.theme-light .hover\:bg-th-main:hover:not(:disabled) {
  background: #D86B47; }

.theme-light .activated\:bg-th-main.active {
  background: #D86B47; }

.theme-light .border-th-main {
  border-color: #D86B47; }

.theme-light .hover\:border-th-main:hover:not(:disabled) {
  border-color: #D86B47; }

.theme-light .activated\:border-th-main.active {
  border-color: #D86B47; }

.theme-light .text-th-white {
  color: white; }

.theme-light .hover\:text-th-white:hover:not(:disabled) {
  color: white; }

.theme-light .activated\:text-th-white.active {
  color: white; }

.theme-light .bg-th-white {
  background: white; }

.theme-light .hover\:bg-th-white:hover:not(:disabled) {
  background: white; }

.theme-light .activated\:bg-th-white.active {
  background: white; }

.theme-light .border-th-white {
  border-color: white; }

.theme-light .hover\:border-th-white:hover:not(:disabled) {
  border-color: white; }

.theme-light .activated\:border-th-white.active {
  border-color: white; }

.theme-light .text-th-white-full {
  color: white; }

.theme-light .hover\:text-th-white-full:hover:not(:disabled) {
  color: white; }

.theme-light .activated\:text-th-white-full.active {
  color: white; }

.theme-light .bg-th-white-full {
  background: white; }

.theme-light .hover\:bg-th-white-full:hover:not(:disabled) {
  background: white; }

.theme-light .activated\:bg-th-white-full.active {
  background: white; }

.theme-light .border-th-white-full {
  border-color: white; }

.theme-light .hover\:border-th-white-full:hover:not(:disabled) {
  border-color: white; }

.theme-light .activated\:border-th-white-full.active {
  border-color: white; }

.theme-light .text-th-black {
  color: black; }

.theme-light .hover\:text-th-black:hover:not(:disabled) {
  color: black; }

.theme-light .activated\:text-th-black.active {
  color: black; }

.theme-light .bg-th-black {
  background: black; }

.theme-light .hover\:bg-th-black:hover:not(:disabled) {
  background: black; }

.theme-light .activated\:bg-th-black.active {
  background: black; }

.theme-light .border-th-black {
  border-color: black; }

.theme-light .hover\:border-th-black:hover:not(:disabled) {
  border-color: black; }

.theme-light .activated\:border-th-black.active {
  border-color: black; }

.theme-light .text-th-light-1 {
  color: #FDFCFA; }

.theme-light .hover\:text-th-light-1:hover:not(:disabled) {
  color: #FDFCFA; }

.theme-light .activated\:text-th-light-1.active {
  color: #FDFCFA; }

.theme-light .bg-th-light-1 {
  background: #FDFCFA; }

.theme-light .hover\:bg-th-light-1:hover:not(:disabled) {
  background: #FDFCFA; }

.theme-light .activated\:bg-th-light-1.active {
  background: #FDFCFA; }

.theme-light .border-th-light-1 {
  border-color: #FDFCFA; }

.theme-light .hover\:border-th-light-1:hover:not(:disabled) {
  border-color: #FDFCFA; }

.theme-light .activated\:border-th-light-1.active {
  border-color: #FDFCFA; }

.theme-light .text-th-light-2 {
  color: #F4F2EE; }

.theme-light .hover\:text-th-light-2:hover:not(:disabled) {
  color: #F4F2EE; }

.theme-light .activated\:text-th-light-2.active {
  color: #F4F2EE; }

.theme-light .bg-th-light-2 {
  background: #F4F2EE; }

.theme-light .hover\:bg-th-light-2:hover:not(:disabled) {
  background: #F4F2EE; }

.theme-light .activated\:bg-th-light-2.active {
  background: #F4F2EE; }

.theme-light .border-th-light-2 {
  border-color: #F4F2EE; }

.theme-light .hover\:border-th-light-2:hover:not(:disabled) {
  border-color: #F4F2EE; }

.theme-light .activated\:border-th-light-2.active {
  border-color: #F4F2EE; }

.theme-light .text-th-light-3 {
  color: #E2DBD3; }

.theme-light .hover\:text-th-light-3:hover:not(:disabled) {
  color: #E2DBD3; }

.theme-light .activated\:text-th-light-3.active {
  color: #E2DBD3; }

.theme-light .bg-th-light-3 {
  background: #E2DBD3; }

.theme-light .hover\:bg-th-light-3:hover:not(:disabled) {
  background: #E2DBD3; }

.theme-light .activated\:bg-th-light-3.active {
  background: #E2DBD3; }

.theme-light .border-th-light-3 {
  border-color: #E2DBD3; }

.theme-light .hover\:border-th-light-3:hover:not(:disabled) {
  border-color: #E2DBD3; }

.theme-light .activated\:border-th-light-3.active {
  border-color: #E2DBD3; }

.theme-light .text-th-dark-1 {
  color: black; }

.theme-light .hover\:text-th-dark-1:hover:not(:disabled) {
  color: black; }

.theme-light .activated\:text-th-dark-1.active {
  color: black; }

.theme-light .bg-th-dark-1 {
  background: black; }

.theme-light .hover\:bg-th-dark-1:hover:not(:disabled) {
  background: black; }

.theme-light .activated\:bg-th-dark-1.active {
  background: black; }

.theme-light .border-th-dark-1 {
  border-color: black; }

.theme-light .hover\:border-th-dark-1:hover:not(:disabled) {
  border-color: black; }

.theme-light .activated\:border-th-dark-1.active {
  border-color: black; }

.theme-light .text-th-dark-2 {
  color: black; }

.theme-light .hover\:text-th-dark-2:hover:not(:disabled) {
  color: black; }

.theme-light .activated\:text-th-dark-2.active {
  color: black; }

.theme-light .bg-th-dark-2 {
  background: black; }

.theme-light .hover\:bg-th-dark-2:hover:not(:disabled) {
  background: black; }

.theme-light .activated\:bg-th-dark-2.active {
  background: black; }

.theme-light .border-th-dark-2 {
  border-color: black; }

.theme-light .hover\:border-th-dark-2:hover:not(:disabled) {
  border-color: black; }

.theme-light .activated\:border-th-dark-2.active {
  border-color: black; }

.theme-light .text-th-blue-1 {
  color: #97c3d5; }

.theme-light .hover\:text-th-blue-1:hover:not(:disabled) {
  color: #97c3d5; }

.theme-light .activated\:text-th-blue-1.active {
  color: #97c3d5; }

.theme-light .bg-th-blue-1 {
  background: #97c3d5; }

.theme-light .hover\:bg-th-blue-1:hover:not(:disabled) {
  background: #97c3d5; }

.theme-light .activated\:bg-th-blue-1.active {
  background: #97c3d5; }

.theme-light .border-th-blue-1 {
  border-color: #97c3d5; }

.theme-light .hover\:border-th-blue-1:hover:not(:disabled) {
  border-color: #97c3d5; }

.theme-light .activated\:border-th-blue-1.active {
  border-color: #97c3d5; }

.theme-light .text-th-blue {
  color: #0077FF; }

.theme-light .hover\:text-th-blue:hover:not(:disabled) {
  color: #0077FF; }

.theme-light .activated\:text-th-blue.active {
  color: #0077FF; }

.theme-light .bg-th-blue {
  background: #0077FF; }

.theme-light .hover\:bg-th-blue:hover:not(:disabled) {
  background: #0077FF; }

.theme-light .activated\:bg-th-blue.active {
  background: #0077FF; }

.theme-light .border-th-blue {
  border-color: #0077FF; }

.theme-light .hover\:border-th-blue:hover:not(:disabled) {
  border-color: #0077FF; }

.theme-light .activated\:border-th-blue.active {
  border-color: #0077FF; }

.theme-light .text-th-yellow-1 {
  color: #FFDF40; }

.theme-light .hover\:text-th-yellow-1:hover:not(:disabled) {
  color: #FFDF40; }

.theme-light .activated\:text-th-yellow-1.active {
  color: #FFDF40; }

.theme-light .bg-th-yellow-1 {
  background: #FFDF40; }

.theme-light .hover\:bg-th-yellow-1:hover:not(:disabled) {
  background: #FFDF40; }

.theme-light .activated\:bg-th-yellow-1.active {
  background: #FFDF40; }

.theme-light .border-th-yellow-1 {
  border-color: #FFDF40; }

.theme-light .hover\:border-th-yellow-1:hover:not(:disabled) {
  border-color: #FFDF40; }

.theme-light .activated\:border-th-yellow-1.active {
  border-color: #FFDF40; }

.theme-light .text-th-yellow-2 {
  color: #CC9831; }

.theme-light .hover\:text-th-yellow-2:hover:not(:disabled) {
  color: #CC9831; }

.theme-light .activated\:text-th-yellow-2.active {
  color: #CC9831; }

.theme-light .bg-th-yellow-2 {
  background: #CC9831; }

.theme-light .hover\:bg-th-yellow-2:hover:not(:disabled) {
  background: #CC9831; }

.theme-light .activated\:bg-th-yellow-2.active {
  background: #CC9831; }

.theme-light .border-th-yellow-2 {
  border-color: #CC9831; }

.theme-light .hover\:border-th-yellow-2:hover:not(:disabled) {
  border-color: #CC9831; }

.theme-light .activated\:border-th-yellow-2.active {
  border-color: #CC9831; }

.theme-light .text-th-yellow-3 {
  color: #E1AB4E; }

.theme-light .hover\:text-th-yellow-3:hover:not(:disabled) {
  color: #E1AB4E; }

.theme-light .activated\:text-th-yellow-3.active {
  color: #E1AB4E; }

.theme-light .bg-th-yellow-3 {
  background: #E1AB4E; }

.theme-light .hover\:bg-th-yellow-3:hover:not(:disabled) {
  background: #E1AB4E; }

.theme-light .activated\:bg-th-yellow-3.active {
  background: #E1AB4E; }

.theme-light .border-th-yellow-3 {
  border-color: #E1AB4E; }

.theme-light .hover\:border-th-yellow-3:hover:not(:disabled) {
  border-color: #E1AB4E; }

.theme-light .activated\:border-th-yellow-3.active {
  border-color: #E1AB4E; }

.theme-light .text-th-yellow-4 {
  color: #f1d746; }

.theme-light .hover\:text-th-yellow-4:hover:not(:disabled) {
  color: #f1d746; }

.theme-light .activated\:text-th-yellow-4.active {
  color: #f1d746; }

.theme-light .bg-th-yellow-4 {
  background: #f1d746; }

.theme-light .hover\:bg-th-yellow-4:hover:not(:disabled) {
  background: #f1d746; }

.theme-light .activated\:bg-th-yellow-4.active {
  background: #f1d746; }

.theme-light .border-th-yellow-4 {
  border-color: #f1d746; }

.theme-light .hover\:border-th-yellow-4:hover:not(:disabled) {
  border-color: #f1d746; }

.theme-light .activated\:border-th-yellow-4.active {
  border-color: #f1d746; }

.theme-light .text-th-brown {
  color: #974A36; }

.theme-light .hover\:text-th-brown:hover:not(:disabled) {
  color: #974A36; }

.theme-light .activated\:text-th-brown.active {
  color: #974A36; }

.theme-light .bg-th-brown {
  background: #974A36; }

.theme-light .hover\:bg-th-brown:hover:not(:disabled) {
  background: #974A36; }

.theme-light .activated\:bg-th-brown.active {
  background: #974A36; }

.theme-light .border-th-brown {
  border-color: #974A36; }

.theme-light .hover\:border-th-brown:hover:not(:disabled) {
  border-color: #974A36; }

.theme-light .activated\:border-th-brown.active {
  border-color: #974A36; }

.theme-light .text-th-orange {
  color: #FF561E; }

.theme-light .hover\:text-th-orange:hover:not(:disabled) {
  color: #FF561E; }

.theme-light .activated\:text-th-orange.active {
  color: #FF561E; }

.theme-light .bg-th-orange {
  background: #FF561E; }

.theme-light .hover\:bg-th-orange:hover:not(:disabled) {
  background: #FF561E; }

.theme-light .activated\:bg-th-orange.active {
  background: #FF561E; }

.theme-light .border-th-orange {
  border-color: #FF561E; }

.theme-light .hover\:border-th-orange:hover:not(:disabled) {
  border-color: #FF561E; }

.theme-light .activated\:border-th-orange.active {
  border-color: #FF561E; }

.theme-light .text-th-danger {
  color: #F70000; }

.theme-light .hover\:text-th-danger:hover:not(:disabled) {
  color: #F70000; }

.theme-light .activated\:text-th-danger.active {
  color: #F70000; }

.theme-light .bg-th-danger {
  background: #F70000; }

.theme-light .hover\:bg-th-danger:hover:not(:disabled) {
  background: #F70000; }

.theme-light .activated\:bg-th-danger.active {
  background: #F70000; }

.theme-light .border-th-danger {
  border-color: #F70000; }

.theme-light .hover\:border-th-danger:hover:not(:disabled) {
  border-color: #F70000; }

.theme-light .activated\:border-th-danger.active {
  border-color: #F70000; }

.theme-light .text-th-orange-2 {
  color: #EFAA93; }

.theme-light .hover\:text-th-orange-2:hover:not(:disabled) {
  color: #EFAA93; }

.theme-light .activated\:text-th-orange-2.active {
  color: #EFAA93; }

.theme-light .bg-th-orange-2 {
  background: #EFAA93; }

.theme-light .hover\:bg-th-orange-2:hover:not(:disabled) {
  background: #EFAA93; }

.theme-light .activated\:bg-th-orange-2.active {
  background: #EFAA93; }

.theme-light .border-th-orange-2 {
  border-color: #EFAA93; }

.theme-light .hover\:border-th-orange-2:hover:not(:disabled) {
  border-color: #EFAA93; }

.theme-light .activated\:border-th-orange-2.active {
  border-color: #EFAA93; }

.theme-light .text-th-green-1 {
  color: #60A777; }

.theme-light .hover\:text-th-green-1:hover:not(:disabled) {
  color: #60A777; }

.theme-light .activated\:text-th-green-1.active {
  color: #60A777; }

.theme-light .bg-th-green-1 {
  background: #60A777; }

.theme-light .hover\:bg-th-green-1:hover:not(:disabled) {
  background: #60A777; }

.theme-light .activated\:bg-th-green-1.active {
  background: #60A777; }

.theme-light .border-th-green-1 {
  border-color: #60A777; }

.theme-light .hover\:border-th-green-1:hover:not(:disabled) {
  border-color: #60A777; }

.theme-light .activated\:border-th-green-1.active {
  border-color: #60A777; }

.theme-light .text-th-outline-1 {
  color: #E2DBD3; }

.theme-light .hover\:text-th-outline-1:hover:not(:disabled) {
  color: #E2DBD3; }

.theme-light .activated\:text-th-outline-1.active {
  color: #E2DBD3; }

.theme-light .bg-th-outline-1 {
  background: #E2DBD3; }

.theme-light .hover\:bg-th-outline-1:hover:not(:disabled) {
  background: #E2DBD3; }

.theme-light .activated\:bg-th-outline-1.active {
  background: #E2DBD3; }

.theme-light .border-th-outline-1 {
  border-color: #E2DBD3; }

.theme-light .hover\:border-th-outline-1:hover:not(:disabled) {
  border-color: #E2DBD3; }

.theme-light .activated\:border-th-outline-1.active {
  border-color: #E2DBD3; }

.theme-light .text-th-outline-2 {
  color: #707070; }

.theme-light .hover\:text-th-outline-2:hover:not(:disabled) {
  color: #707070; }

.theme-light .activated\:text-th-outline-2.active {
  color: #707070; }

.theme-light .bg-th-outline-2 {
  background: #707070; }

.theme-light .hover\:bg-th-outline-2:hover:not(:disabled) {
  background: #707070; }

.theme-light .activated\:bg-th-outline-2.active {
  background: #707070; }

.theme-light .border-th-outline-2 {
  border-color: #707070; }

.theme-light .hover\:border-th-outline-2:hover:not(:disabled) {
  border-color: #707070; }

.theme-light .activated\:border-th-outline-2.active {
  border-color: #707070; }

.theme-light .text-th-disabled {
  color: #d2d2d2; }

.theme-light .hover\:text-th-disabled:hover:not(:disabled) {
  color: #d2d2d2; }

.theme-light .activated\:text-th-disabled.active {
  color: #d2d2d2; }

.theme-light .bg-th-disabled {
  background: #d2d2d2; }

.theme-light .hover\:bg-th-disabled:hover:not(:disabled) {
  background: #d2d2d2; }

.theme-light .activated\:bg-th-disabled.active {
  background: #d2d2d2; }

.theme-light .border-th-disabled {
  border-color: #d2d2d2; }

.theme-light .hover\:border-th-disabled:hover:not(:disabled) {
  border-color: #d2d2d2; }

.theme-light .activated\:border-th-disabled.active {
  border-color: #d2d2d2; }

.theme-light .th-scrollbar::-webkit-scrollbar {
  width: 10px; }

.theme-light .th-scrollbar.th-scrollbar-margins::-webkit-scrollbar-track {
  margin: 10px 0; }

.theme-light .th-scrollbar::-webkit-scrollbar-track {
  background: #FDFCFA; }

.theme-light .th-scrollbar::-webkit-scrollbar-thumb {
  background: black; }

.theme-light .th-scrollbar::-webkit-scrollbar-thumb:hover {
  background: black; }

.theme-light ::selection {
  background-color: #FFDF40;
  color: black; }

.theme-dark .text-th-main {
  color: #D86B47; }

.theme-dark .hover\:text-th-main:hover:not(:disabled) {
  color: #D86B47; }

.theme-dark .activated\:text-th-main.active {
  color: #D86B47; }

.theme-dark .bg-th-main {
  background: #D86B47; }

.theme-dark .hover\:bg-th-main:hover:not(:disabled) {
  background: #D86B47; }

.theme-dark .activated\:bg-th-main.active {
  background: #D86B47; }

.theme-dark .border-th-main {
  border-color: #D86B47; }

.theme-dark .hover\:border-th-main:hover:not(:disabled) {
  border-color: #D86B47; }

.theme-dark .activated\:border-th-main.active {
  border-color: #D86B47; }

.theme-dark .text-th-white {
  color: white; }

.theme-dark .hover\:text-th-white:hover:not(:disabled) {
  color: white; }

.theme-dark .activated\:text-th-white.active {
  color: white; }

.theme-dark .bg-th-white {
  background: white; }

.theme-dark .hover\:bg-th-white:hover:not(:disabled) {
  background: white; }

.theme-dark .activated\:bg-th-white.active {
  background: white; }

.theme-dark .border-th-white {
  border-color: white; }

.theme-dark .hover\:border-th-white:hover:not(:disabled) {
  border-color: white; }

.theme-dark .activated\:border-th-white.active {
  border-color: white; }

.theme-dark .text-th-white-full {
  color: white; }

.theme-dark .hover\:text-th-white-full:hover:not(:disabled) {
  color: white; }

.theme-dark .activated\:text-th-white-full.active {
  color: white; }

.theme-dark .bg-th-white-full {
  background: white; }

.theme-dark .hover\:bg-th-white-full:hover:not(:disabled) {
  background: white; }

.theme-dark .activated\:bg-th-white-full.active {
  background: white; }

.theme-dark .border-th-white-full {
  border-color: white; }

.theme-dark .hover\:border-th-white-full:hover:not(:disabled) {
  border-color: white; }

.theme-dark .activated\:border-th-white-full.active {
  border-color: white; }

.theme-dark .text-th-black {
  color: black; }

.theme-dark .hover\:text-th-black:hover:not(:disabled) {
  color: black; }

.theme-dark .activated\:text-th-black.active {
  color: black; }

.theme-dark .bg-th-black {
  background: black; }

.theme-dark .hover\:bg-th-black:hover:not(:disabled) {
  background: black; }

.theme-dark .activated\:bg-th-black.active {
  background: black; }

.theme-dark .border-th-black {
  border-color: black; }

.theme-dark .hover\:border-th-black:hover:not(:disabled) {
  border-color: black; }

.theme-dark .activated\:border-th-black.active {
  border-color: black; }

.theme-dark .text-th-light-1 {
  color: #2B312D; }

.theme-dark .hover\:text-th-light-1:hover:not(:disabled) {
  color: #2B312D; }

.theme-dark .activated\:text-th-light-1.active {
  color: #2B312D; }

.theme-dark .bg-th-light-1 {
  background: #2B312D; }

.theme-dark .hover\:bg-th-light-1:hover:not(:disabled) {
  background: #2B312D; }

.theme-dark .activated\:bg-th-light-1.active {
  background: #2B312D; }

.theme-dark .border-th-light-1 {
  border-color: #2B312D; }

.theme-dark .hover\:border-th-light-1:hover:not(:disabled) {
  border-color: #2B312D; }

.theme-dark .activated\:border-th-light-1.active {
  border-color: #2B312D; }

.theme-dark .text-th-light-2 {
  color: #232824; }

.theme-dark .hover\:text-th-light-2:hover:not(:disabled) {
  color: #232824; }

.theme-dark .activated\:text-th-light-2.active {
  color: #232824; }

.theme-dark .bg-th-light-2 {
  background: #232824; }

.theme-dark .hover\:bg-th-light-2:hover:not(:disabled) {
  background: #232824; }

.theme-dark .activated\:bg-th-light-2.active {
  background: #232824; }

.theme-dark .border-th-light-2 {
  border-color: #232824; }

.theme-dark .hover\:border-th-light-2:hover:not(:disabled) {
  border-color: #232824; }

.theme-dark .activated\:border-th-light-2.active {
  border-color: #232824; }

.theme-dark .text-th-light-3 {
  color: #1B1F1C; }

.theme-dark .hover\:text-th-light-3:hover:not(:disabled) {
  color: #1B1F1C; }

.theme-dark .activated\:text-th-light-3.active {
  color: #1B1F1C; }

.theme-dark .bg-th-light-3 {
  background: #1B1F1C; }

.theme-dark .hover\:bg-th-light-3:hover:not(:disabled) {
  background: #1B1F1C; }

.theme-dark .activated\:bg-th-light-3.active {
  background: #1B1F1C; }

.theme-dark .border-th-light-3 {
  border-color: #1B1F1C; }

.theme-dark .hover\:border-th-light-3:hover:not(:disabled) {
  border-color: #1B1F1C; }

.theme-dark .activated\:border-th-light-3.active {
  border-color: #1B1F1C; }

.theme-dark .text-th-dark-1 {
  color: white; }

.theme-dark .hover\:text-th-dark-1:hover:not(:disabled) {
  color: white; }

.theme-dark .activated\:text-th-dark-1.active {
  color: white; }

.theme-dark .bg-th-dark-1 {
  background: white; }

.theme-dark .hover\:bg-th-dark-1:hover:not(:disabled) {
  background: white; }

.theme-dark .activated\:bg-th-dark-1.active {
  background: white; }

.theme-dark .border-th-dark-1 {
  border-color: white; }

.theme-dark .hover\:border-th-dark-1:hover:not(:disabled) {
  border-color: white; }

.theme-dark .activated\:border-th-dark-1.active {
  border-color: white; }

.theme-dark .text-th-dark-2 {
  color: white; }

.theme-dark .hover\:text-th-dark-2:hover:not(:disabled) {
  color: white; }

.theme-dark .activated\:text-th-dark-2.active {
  color: white; }

.theme-dark .bg-th-dark-2 {
  background: white; }

.theme-dark .hover\:bg-th-dark-2:hover:not(:disabled) {
  background: white; }

.theme-dark .activated\:bg-th-dark-2.active {
  background: white; }

.theme-dark .border-th-dark-2 {
  border-color: white; }

.theme-dark .hover\:border-th-dark-2:hover:not(:disabled) {
  border-color: white; }

.theme-dark .activated\:border-th-dark-2.active {
  border-color: white; }

.theme-dark .text-th-blue-1 {
  color: #97c3d5; }

.theme-dark .hover\:text-th-blue-1:hover:not(:disabled) {
  color: #97c3d5; }

.theme-dark .activated\:text-th-blue-1.active {
  color: #97c3d5; }

.theme-dark .bg-th-blue-1 {
  background: #97c3d5; }

.theme-dark .hover\:bg-th-blue-1:hover:not(:disabled) {
  background: #97c3d5; }

.theme-dark .activated\:bg-th-blue-1.active {
  background: #97c3d5; }

.theme-dark .border-th-blue-1 {
  border-color: #97c3d5; }

.theme-dark .hover\:border-th-blue-1:hover:not(:disabled) {
  border-color: #97c3d5; }

.theme-dark .activated\:border-th-blue-1.active {
  border-color: #97c3d5; }

.theme-dark .text-th-blue {
  color: #0077FF; }

.theme-dark .hover\:text-th-blue:hover:not(:disabled) {
  color: #0077FF; }

.theme-dark .activated\:text-th-blue.active {
  color: #0077FF; }

.theme-dark .bg-th-blue {
  background: #0077FF; }

.theme-dark .hover\:bg-th-blue:hover:not(:disabled) {
  background: #0077FF; }

.theme-dark .activated\:bg-th-blue.active {
  background: #0077FF; }

.theme-dark .border-th-blue {
  border-color: #0077FF; }

.theme-dark .hover\:border-th-blue:hover:not(:disabled) {
  border-color: #0077FF; }

.theme-dark .activated\:border-th-blue.active {
  border-color: #0077FF; }

.theme-dark .text-th-yellow-1 {
  color: #FFDF40; }

.theme-dark .hover\:text-th-yellow-1:hover:not(:disabled) {
  color: #FFDF40; }

.theme-dark .activated\:text-th-yellow-1.active {
  color: #FFDF40; }

.theme-dark .bg-th-yellow-1 {
  background: #FFDF40; }

.theme-dark .hover\:bg-th-yellow-1:hover:not(:disabled) {
  background: #FFDF40; }

.theme-dark .activated\:bg-th-yellow-1.active {
  background: #FFDF40; }

.theme-dark .border-th-yellow-1 {
  border-color: #FFDF40; }

.theme-dark .hover\:border-th-yellow-1:hover:not(:disabled) {
  border-color: #FFDF40; }

.theme-dark .activated\:border-th-yellow-1.active {
  border-color: #FFDF40; }

.theme-dark .text-th-yellow-2 {
  color: #CC9831; }

.theme-dark .hover\:text-th-yellow-2:hover:not(:disabled) {
  color: #CC9831; }

.theme-dark .activated\:text-th-yellow-2.active {
  color: #CC9831; }

.theme-dark .bg-th-yellow-2 {
  background: #CC9831; }

.theme-dark .hover\:bg-th-yellow-2:hover:not(:disabled) {
  background: #CC9831; }

.theme-dark .activated\:bg-th-yellow-2.active {
  background: #CC9831; }

.theme-dark .border-th-yellow-2 {
  border-color: #CC9831; }

.theme-dark .hover\:border-th-yellow-2:hover:not(:disabled) {
  border-color: #CC9831; }

.theme-dark .activated\:border-th-yellow-2.active {
  border-color: #CC9831; }

.theme-dark .text-th-yellow-3 {
  color: #E1AB4E; }

.theme-dark .hover\:text-th-yellow-3:hover:not(:disabled) {
  color: #E1AB4E; }

.theme-dark .activated\:text-th-yellow-3.active {
  color: #E1AB4E; }

.theme-dark .bg-th-yellow-3 {
  background: #E1AB4E; }

.theme-dark .hover\:bg-th-yellow-3:hover:not(:disabled) {
  background: #E1AB4E; }

.theme-dark .activated\:bg-th-yellow-3.active {
  background: #E1AB4E; }

.theme-dark .border-th-yellow-3 {
  border-color: #E1AB4E; }

.theme-dark .hover\:border-th-yellow-3:hover:not(:disabled) {
  border-color: #E1AB4E; }

.theme-dark .activated\:border-th-yellow-3.active {
  border-color: #E1AB4E; }

.theme-dark .text-th-yellow-4 {
  color: #f1d746; }

.theme-dark .hover\:text-th-yellow-4:hover:not(:disabled) {
  color: #f1d746; }

.theme-dark .activated\:text-th-yellow-4.active {
  color: #f1d746; }

.theme-dark .bg-th-yellow-4 {
  background: #f1d746; }

.theme-dark .hover\:bg-th-yellow-4:hover:not(:disabled) {
  background: #f1d746; }

.theme-dark .activated\:bg-th-yellow-4.active {
  background: #f1d746; }

.theme-dark .border-th-yellow-4 {
  border-color: #f1d746; }

.theme-dark .hover\:border-th-yellow-4:hover:not(:disabled) {
  border-color: #f1d746; }

.theme-dark .activated\:border-th-yellow-4.active {
  border-color: #f1d746; }

.theme-dark .text-th-brown {
  color: #974A36; }

.theme-dark .hover\:text-th-brown:hover:not(:disabled) {
  color: #974A36; }

.theme-dark .activated\:text-th-brown.active {
  color: #974A36; }

.theme-dark .bg-th-brown {
  background: #974A36; }

.theme-dark .hover\:bg-th-brown:hover:not(:disabled) {
  background: #974A36; }

.theme-dark .activated\:bg-th-brown.active {
  background: #974A36; }

.theme-dark .border-th-brown {
  border-color: #974A36; }

.theme-dark .hover\:border-th-brown:hover:not(:disabled) {
  border-color: #974A36; }

.theme-dark .activated\:border-th-brown.active {
  border-color: #974A36; }

.theme-dark .text-th-orange {
  color: #FF561E; }

.theme-dark .hover\:text-th-orange:hover:not(:disabled) {
  color: #FF561E; }

.theme-dark .activated\:text-th-orange.active {
  color: #FF561E; }

.theme-dark .bg-th-orange {
  background: #FF561E; }

.theme-dark .hover\:bg-th-orange:hover:not(:disabled) {
  background: #FF561E; }

.theme-dark .activated\:bg-th-orange.active {
  background: #FF561E; }

.theme-dark .border-th-orange {
  border-color: #FF561E; }

.theme-dark .hover\:border-th-orange:hover:not(:disabled) {
  border-color: #FF561E; }

.theme-dark .activated\:border-th-orange.active {
  border-color: #FF561E; }

.theme-dark .text-th-danger {
  color: #F70000; }

.theme-dark .hover\:text-th-danger:hover:not(:disabled) {
  color: #F70000; }

.theme-dark .activated\:text-th-danger.active {
  color: #F70000; }

.theme-dark .bg-th-danger {
  background: #F70000; }

.theme-dark .hover\:bg-th-danger:hover:not(:disabled) {
  background: #F70000; }

.theme-dark .activated\:bg-th-danger.active {
  background: #F70000; }

.theme-dark .border-th-danger {
  border-color: #F70000; }

.theme-dark .hover\:border-th-danger:hover:not(:disabled) {
  border-color: #F70000; }

.theme-dark .activated\:border-th-danger.active {
  border-color: #F70000; }

.theme-dark .text-th-orange-2 {
  color: #EFAA93; }

.theme-dark .hover\:text-th-orange-2:hover:not(:disabled) {
  color: #EFAA93; }

.theme-dark .activated\:text-th-orange-2.active {
  color: #EFAA93; }

.theme-dark .bg-th-orange-2 {
  background: #EFAA93; }

.theme-dark .hover\:bg-th-orange-2:hover:not(:disabled) {
  background: #EFAA93; }

.theme-dark .activated\:bg-th-orange-2.active {
  background: #EFAA93; }

.theme-dark .border-th-orange-2 {
  border-color: #EFAA93; }

.theme-dark .hover\:border-th-orange-2:hover:not(:disabled) {
  border-color: #EFAA93; }

.theme-dark .activated\:border-th-orange-2.active {
  border-color: #EFAA93; }

.theme-dark .text-th-green-1 {
  color: #60A777; }

.theme-dark .hover\:text-th-green-1:hover:not(:disabled) {
  color: #60A777; }

.theme-dark .activated\:text-th-green-1.active {
  color: #60A777; }

.theme-dark .bg-th-green-1 {
  background: #60A777; }

.theme-dark .hover\:bg-th-green-1:hover:not(:disabled) {
  background: #60A777; }

.theme-dark .activated\:bg-th-green-1.active {
  background: #60A777; }

.theme-dark .border-th-green-1 {
  border-color: #60A777; }

.theme-dark .hover\:border-th-green-1:hover:not(:disabled) {
  border-color: #60A777; }

.theme-dark .activated\:border-th-green-1.active {
  border-color: #60A777; }

.theme-dark .text-th-outline-1 {
  color: #E2DBD3; }

.theme-dark .hover\:text-th-outline-1:hover:not(:disabled) {
  color: #E2DBD3; }

.theme-dark .activated\:text-th-outline-1.active {
  color: #E2DBD3; }

.theme-dark .bg-th-outline-1 {
  background: #E2DBD3; }

.theme-dark .hover\:bg-th-outline-1:hover:not(:disabled) {
  background: #E2DBD3; }

.theme-dark .activated\:bg-th-outline-1.active {
  background: #E2DBD3; }

.theme-dark .border-th-outline-1 {
  border-color: #E2DBD3; }

.theme-dark .hover\:border-th-outline-1:hover:not(:disabled) {
  border-color: #E2DBD3; }

.theme-dark .activated\:border-th-outline-1.active {
  border-color: #E2DBD3; }

.theme-dark .text-th-outline-2 {
  color: #707070; }

.theme-dark .hover\:text-th-outline-2:hover:not(:disabled) {
  color: #707070; }

.theme-dark .activated\:text-th-outline-2.active {
  color: #707070; }

.theme-dark .bg-th-outline-2 {
  background: #707070; }

.theme-dark .hover\:bg-th-outline-2:hover:not(:disabled) {
  background: #707070; }

.theme-dark .activated\:bg-th-outline-2.active {
  background: #707070; }

.theme-dark .border-th-outline-2 {
  border-color: #707070; }

.theme-dark .hover\:border-th-outline-2:hover:not(:disabled) {
  border-color: #707070; }

.theme-dark .activated\:border-th-outline-2.active {
  border-color: #707070; }

.theme-dark .text-th-disabled {
  color: #d2d2d2; }

.theme-dark .hover\:text-th-disabled:hover:not(:disabled) {
  color: #d2d2d2; }

.theme-dark .activated\:text-th-disabled.active {
  color: #d2d2d2; }

.theme-dark .bg-th-disabled {
  background: #d2d2d2; }

.theme-dark .hover\:bg-th-disabled:hover:not(:disabled) {
  background: #d2d2d2; }

.theme-dark .activated\:bg-th-disabled.active {
  background: #d2d2d2; }

.theme-dark .border-th-disabled {
  border-color: #d2d2d2; }

.theme-dark .hover\:border-th-disabled:hover:not(:disabled) {
  border-color: #d2d2d2; }

.theme-dark .activated\:border-th-disabled.active {
  border-color: #d2d2d2; }

.theme-dark .th-scrollbar::-webkit-scrollbar {
  width: 10px; }

.theme-dark .th-scrollbar.th-scrollbar-margins::-webkit-scrollbar-track {
  margin: 10px 0; }

.theme-dark .th-scrollbar::-webkit-scrollbar-track {
  background: #2B312D; }

.theme-dark .th-scrollbar::-webkit-scrollbar-thumb {
  background: white; }

.theme-dark .th-scrollbar::-webkit-scrollbar-thumb:hover {
  background: white; }

.theme-dark ::selection {
  background-color: #FFDF40;
  color: white; }

/* ----------------------------------------------------------- *
 |
 |  Mixins
 |
 * ----------------------------------------------------------- *
 |
 | utilities
 |
 * ----------------------------------------------------------- */
.fx-scale {
  outline: none !important;
  transform: scale(1);
  transition: transform 0.15s ease; }
  .fx-scale:focus, .fx-scale:hover {
    transform: scale(1.05) !important; }
  .fx-scale:active {
    transform: scale(0.9) !important; }

.fx-bump {
  animation: bump .5s forwards; }

.fx-rainbow {
  animation: rainbow 12s infinite linear;
  background: linear-gradient(white, white), repeating-linear-gradient(to right, #b827fc 0%, #2c90fc 11.1111%, #b8fd33 22.2222%, #fec837 33.3333%, #fd1892 44.4444%, #fd1892 55.5555%, #fec837 66.6666%, #b8fd33 77.7777%, #2c90fc 88.8888%, #b827fc 100%);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: solid 5px transparent;
  border-radius: 12px; }

.fx-floating {
  position: absolute;
  pointer-events: none;
  z-index: 10000; }

@keyframes rainbow {
  from {
    background-position: -100vh -100vh; }
  to {
    background-position: 100vh 100vh; } }

@keyframes bump {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.25); }
  100% {
    transform: scale(1); } }

/* ----------------------------------------------------------- *
 |
 |  Mixins
 |
 * ----------------------------------------------------------- *
 |
 | utilities
 |
 * ----------------------------------------------------------- */
/** layout **/
/** fonts **/
.button {
  outline: none !important;
  transform: scale(1);
  transition: transform 0.15s ease;
  transition: opacity 0.3s ease-in-out, transform 0.15s ease; }
  .button:focus, .button:hover {
    transform: scale(1.05) !important; }
  .button:active {
    transform: scale(0.9) !important; }

.button:disabled,
.button[disabled] {
  opacity: 0.2; }

.button:disabled:hover,
.button[disabled]:hover {
  transform: none !important;
  cursor: not-allowed; }

.button-white,
.button-yellow,
.button-blue {
  font-family: "herkules", serif;
  padding: 0.4rem 2rem;
  font-size: 1.5rem; }

.button-icon {
  padding: 0.4rem 1rem; }

.button-orange {
  border-radius: 50%;
  font-size: 1.875rem;
  line-height: 2.25rem;
  outline: 0;
  padding: .5rem; }
  .button-orange .icon {
    display: block;
    font-size: 2.25rem; }

.button-yellow {
  background: #FFDF40;
  color: black;
  border: 1px solid black;
  text-decoration: none; }

.open-menu {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: 0;
  padding: 0.3rem 0.5rem;
  z-index: 4000; }
  .open-menu:hover {
    cursor: pointer; }
  @media (min-width: 992px) {
    .open-menu {
      display: none; } }

.close-menu {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  background: none;
  border: none;
  font-size: 2rem;
  z-index: 4000; }
  @media (min-width: 992px) {
    .close-menu {
      display: none; } }

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'herkules', serif;
  font-size: 1.5rem;
  z-index: 5000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  background: black; }
  @media (min-width: 992px) {
    .menu {
      background: transparent;
      position: absolute;
      top: 2rem;
      bottom: auto;
      left: 3rem;
      right: auto;
      opacity: 1;
      pointer-events: all; } }
  .menu.is-open {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease, background 0.3s ease; }
  .menu ul {
    list-style: none;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20vh 0 0 0; }
    @media (min-width: 992px) {
      .menu ul {
        display: block;
        margin: 0; } }
  .menu li {
    margin-bottom: 1.5rem; }
    @media (min-width: 992px) {
      .menu li {
        float: left;
        margin-right: 2rem;
        margin-bottom: 0; } }
  .menu a {
    text-decoration: none;
    color: white; }
    @media (min-width: 992px) {
      .menu a {
        color: black; } }
    .menu a:visited {
      color: white; }
      @media (min-width: 992px) {
        .menu a:visited {
          color: black; } }
    .menu a:hover {
      text-decoration: underline; }

.lang-menu {
  position: absolute;
  top: 1.2rem;
  right: 6rem;
  background: transparent;
  border: none;
  border: 0;
  width: 50px;
  z-index: 5000; }
  .lang-menu:hover {
    cursor: pointer; }
  @media (min-width: 768px) {
    .lang-menu {
      position: absolute;
      top: 2rem;
      right: 6.5rem; } }
  .lang-menu option {
    margin: 40px;
    background: #EBEBE9;
    border: 0;
    color: #000;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

/*
  Color spots
 */
.color-spots {
  pointer-events: none; }

#color-spot-br {
  position: fixed;
  top: 0;
  bottom: -11vh;
  right: 0;
  background: url("../img/00-stain-colored-bottomright.png") no-repeat right bottom/contain;
  max-width: 100vh;
  width: 120vw;
  z-index: -1; }
  @media (min-width: 992px) {
    #color-spot-br {
      bottom: -20vh;
      max-width: 1200px; } }
  @media (min-width: 1200px) {
    #color-spot-br {
      bottom: -20vh;
      max-width: 1300px; } }

#color-spot-tl {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/00-stain-colored-topleft.png") no-repeat left top/contain;
  max-width: 100vh;
  width: 120vw;
  z-index: -1; }
  @media (min-width: 992px) {
    #color-spot-tl {
      max-width: 1200px; } }
  @media (min-width: 1200px) {
    #color-spot-tl {
      max-width: 1400px; } }

#color-spot-bl {
  position: fixed;
  top: 0;
  bottom: -25vh;
  left: 0;
  right: 0;
  background: url("../img/stain-colored-bottomleft.png") no-repeat left bottom/contain;
  max-width: 100vh;
  width: 120vw;
  z-index: -1; }
  @media (min-width: 992px) {
    #color-spot-bl {
      bottom: -30vh;
      max-width: 1200px; } }

/**
  Sections
 */
section {
  height: 100%;
  pointer-events: none;
  position: relative; }
  section p {
    font-size: 1.2rem;
    padding: 0 2rem;
    text-align: center; }
    @media (min-width: 768px) {
      section p {
        font-size: 1.5rem; } }

/**
    Section 1: Logo
 */
#trigger-logo {
  position: absolute;
  top: calc(var(--app-height) * 0.6);
  bottom: 0;
  left: 0;
  right: 0; }

.section-logo {
  height: var(--app-height);
  pointer-events: all;
  text-align: center; }
  .section-logo #logo-button-start {
    margin-bottom: 1rem;
    padding: 1rem 2.5rem;
    position: relative;
    z-index: 100; }
    @media (min-width: 768px) {
      .section-logo #logo-button-start {
        margin-bottom: 1.6rem; } }
  .section-logo p {
    margin: 0 auto 0 auto;
    text-align: center; }
  .section-logo .logo-duration,
  .section-logo .logo-auto-save {
    color: #6a6a6a;
    font-size: 0.8rem;
    font-style: italic; }
    @media (min-width: 768px) {
      .section-logo .logo-duration,
      .section-logo .logo-auto-save {
        font-size: 1rem; } }
  .section-logo .logo-subtitle {
    font-size: 1.2rem;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 4vh;
    margin-top: 2vh;
    padding: 0 1rem; }
    @media (min-width: 768px) {
      .section-logo .logo-subtitle {
        font-size: 1.2rem;
        margin-bottom: 6vh;
        width: 90%; } }
    @media (min-width: 992px) {
      .section-logo .logo-subtitle {
        font-size: 1.4rem;
        line-height: 1.5rem;
        margin-top: 5vh; } }
    @media (min-width: 1200px) {
      .section-logo .logo-subtitle {
        font-size: 1.7rem;
        line-height: 2.3rem;
        width: 1200px; } }

#scroll-tuto-info {
  position: fixed;
  bottom: 3.5rem;
  left: 2rem;
  right: 2rem;
  color: #6a6a6a;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: lighter;
  margin: 0 auto;
  padding: 0;
  pointer-events: all;
  text-align: center;
  transition: opacity 0.2s ease;
  z-index: 4000; }
  @media (min-width: 768px) {
    #scroll-tuto-info {
      position: fixed;
      bottom: 3.3rem;
      left: 2rem;
      right: 2rem;
      font-size: 1.6rem; } }
  #scroll-tuto-info.is-hidden {
    opacity: 0;
    pointer-events: none; }
  #scroll-tuto-info a {
    color: #969696;
    text-decoration: underline; }
    #scroll-tuto-info a:hover {
      text-decoration: none; }
    #scroll-tuto-info a:focus, #scroll-tuto-info a:visited {
      color: #969696; }

#logo-image {
  margin-top: 7vh;
  max-width: 70%;
  position: relative;
  top: 0;
  transition: top 0.2s ease;
  width: 55%; }
  @media (min-width: 768px) {
    #logo-image {
      height: 36vh;
      max-width: 50vh;
      width: auto; } }
  @media (min-width: 992px) {
    #logo-image {
      height: 37vh;
      max-width: none; } }

/**
    Section 2: Votation date
 */
.section-votation-date {
  height: calc(3.3 * var(--app-height));
  overflow: hidden; }
  .section-votation-date p {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: var(--app-height);
    justify-content: center;
    margin: 0; }
    @media (min-width: 768px) {
      .section-votation-date p {
        flex-direction: row; } }

#trigger-date {
  position: absolute;
  top: calc(var(--app-height) * 0.5); }

#trigger-bg-yes {
  position: absolute;
  top: calc(var(--app-height) * 0.75); }

#trigger-pin-bg {
  position: absolute;
  top: calc(var(--app-height) * 1.5); }

#trigger-show-papers {
  position: absolute;
  top: calc(var(--app-height) * 0.9); }

#trigger-show-votation-mountains {
  position: absolute;
  top: calc(var(--app-height) * 2.3); }

#trigger-throw-paper {
  position: absolute;
  top: calc(var(--app-height) * 2.7); }

#bg-wrapper {
  height: var(--app-height); }

#bg-ul-1,
#bg-ol-1,
#bg-ol-2,
#bg-ol-3 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none; }

#bg-ul-1 {
  background: url("../img/01-UL1.png") no-repeat center center/contain; }

#bg-ol-1 {
  background: url("../img/01-OL1.png") no-repeat center center/contain; }

#bg-ol-2 {
  background: url("../img/01-OL2.png") no-repeat center center/contain; }

#bg-ol-3 {
  background: url("../img/01-OL3.png") no-repeat center center/contain;
  height: 400px;
  width: 600px; }

#papers {
  position: fixed;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  background: url("../img/papers.png") no-repeat center center/contain;
  height: 300px;
  margin-left: -150px;
  margin-top: -150px;
  max-width: 100%;
  opacity: 0;
  pointer-events: none;
  transform: scale(5);
  width: 300px; }
  @media (min-width: 768px) {
    #papers {
      height: 60vh;
      margin-left: -30vh;
      margin-top: -30vh;
      width: 60vh; } }

/**
    Section 3: Activists
 */
.section-activists {
  height: calc(2.5 * var(--app-height));
  overflow: visible;
  z-index: 4; }

#activists-text {
  height: 200px;
  margin-top: 3rem;
  text-align: center; }
  @media (min-width: 768px) {
    #activists-text {
      position: absolute;
      top: 2rem;
      bottom: 0;
      right: 4rem;
      height: 200px;
      margin-top: 0;
      text-align: left;
      width: 35vw; } }

#pin-activists {
  font-size: 1.5rem;
  height: var(--app-height);
  overflow: hidden; }

#trigger-pin-activists {
  position: absolute;
  top: calc(var(--app-height) * 0.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-show-activists-stains {
  position: absolute;
  top: calc(var(--app-height) * 0.55);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-fade-activists-text {
  position: absolute;
  top: calc(var(--app-height) * 1.5);
  bottom: 0;
  left: 0;
  right: 0; }

#votation-date-mountains-left {
  position: fixed;
  top: 0;
  bottom: -15rem;
  left: 50%;
  right: 0;
  background: url("../img/02-mountain-large.png") no-repeat center bottom/contain;
  height: 125vh;
  margin-left: -100vh;
  transform: translateY(150vh);
  width: 200vh;
  z-index: 5000; }
  @media (min-width: 1200px) {
    #votation-date-mountains-left {
      position: fixed;
      top: -15vh;
      bottom: -22rem;
      left: -20vw;
      height: auto;
      margin-left: 0;
      width: auto; } }

#votation-date-mountains-right {
  position: fixed;
  top: 0;
  bottom: -15rem;
  left: 50%;
  right: 0;
  background: url("../img/02-mountain-large-r.png") no-repeat center bottom/contain;
  height: 125vh;
  margin-left: -95vh;
  transform: translateY(150vh);
  width: 260vh;
  z-index: 5000; }
  @media (min-width: 1200px) {
    #votation-date-mountains-right {
      position: fixed;
      top: -15vh;
      bottom: -37rem;
      right: -52vw;
      height: auto;
      width: auto; } }

#activists-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--app-height); }

#activists-stains {
  margin-left: -50vh;
  position: absolute;
  bottom: -50vh;
  left: 50%;
  right: 0;
  width: 100vh; }
  @media (min-width: 768px) {
    #activists-stains {
      margin-left: -800px;
      position: absolute;
      bottom: -34vh;
      left: 50%;
      right: 0;
      width: 1600px; } }
  @media (min-width: 992px) {
    #activists-stains {
      position: absolute;
      bottom: -20vh;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 2000px;
      width: 100vw; } }
  #activists-stains #ac-sc-1 {
    position: absolute;
    bottom: 0;
    left: -10vh;
    background: url("../img/stain-bw-left.png") no-repeat center center/cover;
    height: 80vh;
    opacity: 0.7;
    transform: translateY(80vh);
    width: 80vh; }
  #activists-stains #ac-sc-2 {
    position: absolute;
    bottom: 0vh;
    left: 50%;
    right: 0;
    background: url("../img/stain-bw-middle.png") no-repeat center center/cover;
    height: 100vh;
    margin-left: -60vh;
    opacity: 0.5;
    transform: translateY(100vh);
    width: 100vh; }
  #activists-stains #ac-sc-3 {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("../img/stain-bw-right.png") no-repeat center center/cover;
    height: 80vh;
    opacity: 0.6;
    transform: translateY(80vh);
    width: 80vh; }

#activists-crowd-bg {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  background: url("../img/02-crowd.png") no-repeat bottom center/contain;
  height: 50vh;
  margin-left: -1000px;
  transform: translateY(50vh);
  width: 2000px; }
  @media (min-width: 768px) {
    #activists-crowd-bg {
      height: 60vh;
      transform: translateY(60vh); } }
  @media (min-width: 992px) {
    #activists-crowd-bg {
      position: absolute;
      bottom: -15vh;
      left: 0;
      right: 0;
      height: 100vh;
      margin: 0 auto;
      max-width: 1920px;
      transform: translateY(100vh);
      width: 100vw; } }

.activist-1 {
  position: absolute;
  top: 0;
  bottom: -1rem;
  left: calc(50% - 12rem);
  right: 0;
  background: url("../img/02-woman-left.png") no-repeat bottom center/contain;
  margin-left: -6rem;
  width: 12rem; }
  @media (min-width: 768px) {
    .activist-1 {
      position: absolute;
      top: 0;
      bottom: -1rem;
      left: calc(50% - 20rem);
      right: 0;
      margin-left: -12rem;
      width: 24rem; } }

.activist-2 {
  position: absolute;
  top: 0;
  bottom: -1rem;
  left: calc(50% - 3rem);
  right: 0;
  background: url("../img/02-woman-middle.png") no-repeat bottom center/contain;
  margin-left: -9rem;
  width: 18rem; }
  @media (min-width: 768px) {
    .activist-2 {
      position: absolute;
      top: 0;
      bottom: -1rem;
      left: 50%;
      right: 0;
      margin-left: -21rem;
      width: 36rem; } }

.activist-3 {
  position: absolute;
  top: 0;
  bottom: -1rem;
  left: calc(50% + 8rem);
  right: 0;
  background: url("../img/02-woman-right.png") no-repeat bottom center/contain;
  margin-left: -5rem;
  width: 10rem; }
  @media (min-width: 768px) {
    .activist-3 {
      position: absolute;
      top: 0;
      bottom: -1rem;
      left: calc(50% + 37vh);
      right: 0;
      margin-left: -20vh;
      max-width: 300px;
      width: 39vh; } }

/**
    Section 4: Crowd
 */
#trigger-zoom-activists {
  position: absolute;
  top: calc(var(--app-height) * 0.6);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-pin-crowd {
  position: absolute;
  top: calc(var(--app-height) * 0.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-zoom-crowd {
  position: absolute;
  top: calc(var(--app-height) * 1.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-translate-crowd {
  position: absolute;
  top: calc(var(--app-height) * 0.4);
  bottom: 0;
  left: 0;
  right: 0; }

.section-crowd {
  height: calc(var(--app-height) * 1.5);
  overflow: hidden;
  z-index: 2; }

#crowd-text {
  margin-top: 3rem; }
  @media (min-width: 768px) {
    #crowd-text {
      position: absolute;
      top: 0.5vh;
      bottom: 0;
      right: 1rem;
      height: 200px;
      margin-top: 0;
      width: 35rem; } }
  #crowd-text p {
    text-align: center; }
    @media (min-width: 768px) {
      #crowd-text p {
        margin-top: 1.5vh;
        text-align: left; } }

.crowd-bgs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--app-height);
  overflow: hidden; }

#crowd-ol-1,
#crowd-ol-2,
#crowd-ul-1,
#crowd-ul-2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  display: flex;
  height: var(--app-height);
  justify-content: center; }

#crowd-ol-1 img {
  height: 92vh;
  margin-bottom: 7vh;
  transform: translateY(7rem); }
  @media (min-width: 768px) {
    #crowd-ol-1 img {
      height: auto;
      margin-bottom: 15vh;
      transform: translateY(22rem); } }

#crowd-ol-2 img {
  height: 40vh;
  margin-top: 60vh;
  transform: translateY(4rem); }
  @media (min-width: 768px) {
    #crowd-ol-2 img {
      margin-top: 61vh; } }

#crowd-ul-1 img {
  margin-bottom: 10vh;
  transform: translateY(12rem); }
  @media (min-width: 768px) {
    #crowd-ul-1 img {
      margin-bottom: 28vh;
      transform: translateY(35rem); } }

#crowd-ul-2 img {
  margin-bottom: 20vh;
  transform: translateY(15rem); }
  @media (min-width: 768px) {
    #crowd-ul-2 img {
      display: block;
      height: auto;
      margin-bottom: 35vh;
      transform: translateY(30rem); } }

/**
    Section 5: Anna
 */
.section-anna {
  height: calc(2.3 * var(--app-height));
  overflow: hidden;
  z-index: 2; }

#trigger-pin-anna {
  position: absolute;
  top: calc(var(--app-height) * 0.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-anna-show-sn {
  position: absolute;
  top: calc(var(--app-height) * 0.6);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-anna-show-stains {
  position: absolute;
  top: calc(var(--app-height) * 0.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-show-anna-phone {
  position: absolute;
  top: calc(var(--app-height) * 1.6);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-scale-down-anna {
  position: absolute;
  top: calc(var(--app-height) * 2.4);
  bottom: 0;
  left: 0;
  right: 0; }

#anna-bgs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  opacity: 0;
  pointer-events: none; }

#anna-bg-shout {
  position: absolute;
  top: 2rem;
  left: -50%;
  background: url("../img/04-shout.png") no-repeat center center/contain;
  height: 80vh;
  transform: rotate(-10deg);
  width: 30%; }

#anna-bg-shoutbox {
  position: absolute;
  top: 2rem;
  right: -50%;
  background: url("../img/04-shoutbox.png") no-repeat center center/contain;
  height: 80vh;
  transform: rotate(10deg);
  width: 30%; }

#anna-text {
  margin: 3rem auto auto auto;
  opacity: 0; }
  @media (min-width: 768px) {
    #anna-text {
      margin: 5rem auto auto auto;
      width: 500px; } }

#anna-bg {
  position: absolute;
  top: 50vh;
  bottom: -5rem;
  left: 0;
  right: 0;
  background: url("../img/04-anna.png") no-repeat center center/contain; }

#anna-stain-l {
  position: absolute;
  bottom: -30vh;
  left: 50%;
  right: 0;
  background: url("../img/stain-bw-left.png") no-repeat center center/contain;
  height: 100vh;
  margin-left: -64vh;
  opacity: 0.5;
  transform: translateY(100vh) scale(0.8);
  width: 100vh; }

#anna-stain-r {
  position: absolute;
  bottom: -33vh;
  left: 50%;
  right: 0;
  background: url("../img/stain-bw-right.png") no-repeat center center/contain;
  height: 100vh;
  margin-left: -40vh;
  opacity: 0.5;
  transform: translateY(100vh) scale(0.8);
  width: 100vh; }

#anna-phone {
  position: absolute;
  top: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  background: url("../img/05-phone.png") no-repeat center center/contain;
  height: 100vh;
  margin: 0 auto;
  width: 28rem; }
  @media (min-width: 992px) {
    #anna-phone {
      height: 170vh;
      margin-left: -4vh;
      width: 136vw; } }
  @media (min-width: 1200px) {
    #anna-phone {
      height: 170vh;
      margin-left: 0;
      width: 108vw; } }

/**
    Section 6: Anna in phone
 */
#trigger-pin-anna-in-phone {
  position: absolute;
  top: calc(var(--app-height) * 0.5);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-fade-anna-in-phone {
  position: absolute;
  top: calc(var(--app-height) * 0.2);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-move-out-anna-in-phone {
  position: absolute;
  top: calc(var(--app-height) * 1);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-end-scroll {
  position: absolute;
  top: calc(var(--app-height) * 2);
  bottom: 0;
  left: 0;
  right: 0; }

#trigger-show-start-button {
  position: absolute;
  top: calc(var(--app-height) * 1.5);
  bottom: 0;
  left: 0;
  right: 0; }

#logo-button-start,
#anna-in-phone-button-start {
  align-items: center;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
  font-size: 1.5rem; }

#anna-in-phone-button-start {
  position: fixed;
  bottom: 15vh;
  left: 2rem;
  right: 2rem;
  margin: auto;
  opacity: 0;
  padding: 1.5rem 1rem;
  pointer-events: none;
  text-align: center;
  width: 180px;
  z-index: 6000; }
  @media (min-width: 768px) {
    #anna-in-phone-button-start {
      position: fixed;
      bottom: 15vh;
      left: 50%;
      right: auto;
      font-size: 2rem;
      margin-left: -90px;
      padding: 2rem 1rem; } }
  #anna-in-phone-button-start.is-clickable {
    pointer-events: all; }

.section-anna-in-phone {
  height: calc(var(--app-height) * 3);
  overflow: hidden;
  z-index: 1; }

.anna-in-phone-text-2-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  display: flex;
  font-size: 2rem;
  height: var(--app-height);
  justify-content: center; }

#anna-in-phone-bgs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--app-height);
  opacity: 0; }

#anna-in-phone-bg {
  position: absolute;
  top: 15vh;
  bottom: 0;
  left: 9vh;
  right: 0;
  background: url("../img/05-phone-web.png") no-repeat center center/contain;
  height: 100vh; }
  @media (min-width: 768px) {
    #anna-in-phone-bg {
      position: absolute;
      top: 17vh;
      bottom: 0;
      left: 17vh;
      right: 0;
      height: 100vh; } }
  @media (min-width: 992px) {
    #anna-in-phone-bg {
      position: absolute;
      top: 17vh;
      bottom: 0;
      left: 17vh;
      right: 0;
      height: 100vh; } }

#anna-in-phone-text-1 {
  font-size: 1.5rem;
  margin: 3rem auto auto auto;
  max-width: 80%;
  width: 600px; }

#anna-in-phone-text-2 {
  font-size: 1.5rem;
  opacity: 0; }
  @media (min-width: 768px) {
    #anna-in-phone-text-2 {
      font-size: 2rem; } }

#scroll-wrapper, #content-wrapper {
  height: var(--app-height); }

.scrollContainer {
  height: var(--app-height);
  margin: auto; }

.scrollContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  -webkit-overflow-scrolling: touch; }

#content-wrapper.scrollContainer .scrollContent {
  width: 100%;
  height: auto; }

.branding-logo {
  position: absolute;
  top: 0.6rem;
  right: 1rem;
  height: 40px;
  width: auto; }
  @media (min-width: 992px) {
    .branding-logo {
      height: 50px;
      position: absolute;
      top: 1rem;
      right: 1rem; } }

#scroll-down {
  position: fixed;
  bottom: 1.3rem;
  left: 0;
  right: 0;
  font-size: 1.2rem;
  text-align: center;
  pointer-events: none;
  opacity: 0.3;
  animation-name: scroll;
  animation-duration: 4s;
  animation-iteration-count: infinite; }

@keyframes scroll {
  0% {
    position: fixed;
    bottom: 1.1rem;
    left: 0;
    right: 0; }
  5% {
    position: fixed;
    bottom: 0.4rem;
    left: 0;
    right: 0; }
  10% {
    position: fixed;
    bottom: 1.1rem;
    left: 0;
    right: 0; } }

.credits {
  background: black;
  color: white;
  text-align: center;
  padding: 2rem; }
  .credits main {
    padding-bottom: 2rem; }
  .credits ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .credits .title-logo {
    margin-top: 1rem; }
  .credits img {
    margin-top: 3rem;
    width: 100px; }
  .credits .logo-dna {
    width: 180px;
    margin-top: 0; }
    @media (min-width: 768px) {
      .credits .logo-dna {
        width: auto; } }

.about {
  background: black;
  color: white;
  padding: 2rem; }
  .about h1 {
    text-align: center;
    margin-bottom: 2.2rem; }
    @media (min-width: 768px) {
      .about h1 {
        margin-bottom: 4rem; } }
  .about p {
    max-width: 800px;
    margin: 1.6rem auto; }
